// import React, { useEffect, useRef, useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import axios from "../../axios";
// import AddBlog from "./AddBlog";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content"; // Import the wrapper
// import JoditEditor from "jodit-react";
// import AssetImageUpload from "./AssetImageUpload";

// const MySwal = withReactContent(Swal); // Create a Swal instance with ReactContent

// function AddBlogForm() {
//   const editor = useRef(null);
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     title: "",
//     shortTitle: "",
//     metaTitle: "",
//     metaDescription: "",
//     metaTag: "",
//     slug: " ",
//   });
//   const [coverImage, setCoverImage] = useState(null);
//   const [closeForm, setCloseForm] = useState(false);
//   const [editorContent, setEditorContent] = useState("");
//   const [isSubmitting, setIsSubmitting] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleCoverImageChange = (e) => {
//     setCoverImage(e.target.files[0]);
//   };

//   const contentFieldChanged = (data) => {
//     setEditorContent(data);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsSubmitting(true);

//     const { title, shortTitle, metaTitle, metaDescription, metaTag, slug } =
//       formData;

//     if (
//       !title ||
//       !shortTitle ||
//       !metaTitle ||
//       !metaDescription ||
//       !metaTag ||
//       !slug ||
//       !editorContent
//     ) {
//       toast.error("All fields are mandatory");
//       setIsSubmitting(false);
//       return;
//     }

//     const formDataToSend = new FormData();
//     formDataToSend.append("title", title);
//     formDataToSend.append("shortTitle", shortTitle);
//     formDataToSend.append("metaTitle", metaTitle);
//     formDataToSend.append("metaDescription", metaDescription);
//     formDataToSend.append("metaTags", metaTag);
//     formDataToSend.append("slug", slug.toLocaleLowerCase());
//     formDataToSend.append("editorContent", editorContent);

//     if (coverImage) {
//       formDataToSend.append("image", coverImage);
//     }

//     try {
//       const response = await axios.post("/blog", formDataToSend, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });

//       console.log("Server response:", response.data);

//       if (response.data.success) {
//         toast.success("Blog has been added successfully");
//         setCloseForm(true);
//         navigate("/blog-management");
//       } else {
//         toast.error(response.data.message);
//       }
//     } catch (error) {
//       console.error("Error submitting the form:", error);
//       toast.error("Failed to add blog. Please try again later.");
//     } finally {
//       setIsSubmitting(false); // Stop submission
//     }
//   };

//   const handleAssetImageUpload = (imageUrl) => {
//     console.log("Received image URL:", imageUrl);
//     // You can set it in the form state or do something else with it
//   };

//   const showAssetImageUploadModal = () => {
//     MySwal.fire({
//       title: "Upload Asset Image",
//       html: <AssetImageUpload onUpload={handleAssetImageUpload} />,
//       showConfirmButton: false, // You can add a confirm button if needed
//       showCloseButton: true,
//       allowOutsideClick: false,
//     });
//   };

//   const closeFormAndOpenPage = () => {
//     setCloseForm(true);
//   };

//   if (closeForm) {
//     return <AddBlog />;
//   }

//   return (
//     <>
//       <ToastContainer />
//       {closeForm ? (
//         <AddBlog />
//       ) : (
//         <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
//           <h2 className="text-2xl font-semibold mb-6">Add Blog Post</h2>
//           <div className="flex justify-end">
//             <button
//               onClick={closeFormAndOpenPage}
//               className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
//               style={{
//                 backgroundImage:
//                   "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
//               }}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="16"
//                 height="16"
//                 fill="currentColor"
//                 className="bi bi-arrow-left"
//                 viewBox="0 0 16 16"
//               >
//                 <path
//                   fillRule="evenodd"
//                   d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
//                 />
//               </svg>
//               <span className="ml-2">Back</span>
//             </button>
//           </div>
//           <form onSubmit={handleSubmit} encType="multipart/form-data">
//             {/* Title */}
//             <div className="mb-4">
//               <label
//                 htmlFor="title"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Title
//               </label>
//               <input
//                 type="text"
//                 id="title"
//                 name="title"
//                 value={formData.title}
//                 onChange={handleInputChange}
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//             </div>

//             {/* Short Title */}
//             <div className="mb-4">
//               <label
//                 htmlFor="shortTitle"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Short Title
//               </label>
//               <input
//                 type="text"
//                 id="shortTitle"
//                 name="shortTitle"
//                 value={formData.shortTitle}
//                 onChange={handleInputChange}
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//             </div>

//             {/* Meta Title */}
//             <div className="mb-4">
//               <label
//                 htmlFor="metaTitle"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Meta Title
//               </label>
//               <input
//                 type="text"
//                 id="metaTitle"
//                 name="metaTitle"
//                 value={formData.metaTitle}
//                 onChange={handleInputChange}
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//             </div>

//             {/* Meta Description */}
//             <div className="mb-4">
//               <label
//                 htmlFor="metaDescription"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Meta Description
//               </label>
//               <textarea
//                 id="metaDescription"
//                 name="metaDescription"
//                 value={formData.metaDescription}
//                 onChange={handleInputChange}
//                 rows="3"
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               ></textarea>
//             </div>

//             {/* Meta Tag */}
//             <div className="mb-4">
//               <label
//                 htmlFor="metaTag"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Meta Tag
//               </label>
//               <input
//                 type="text"
//                 id="metaTag"
//                 name="metaTag"
//                 value={formData.metaTag}
//                 onChange={handleInputChange}
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//             </div>

//             {/* Slug */}
//             <div className="mb-4">
//               <label
//                 htmlFor="slug"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Slug
//               </label>
//               <input
//                 type="text"
//                 id="slug"
//                 name="slug"
//                 value={formData.slug}
//                 onChange={handleInputChange}
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//             </div>

//             {/* Image Upload */}
//             <div className="mb-4">
//               <label
//                 htmlFor="image"
//                 className="block text-sm font-medium text-gray-700"
//               >
//                 Cover Image Upload
//               </label>
//               <input
//                 type="file"
//                 onChange={handleCoverImageChange}
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//               />
//             </div>

//             <div className="mb-4">
//               <button
//                 type="button"
//                 className="bg-indigo-500 text-white px-4 py-2 rounded"
//                 onClick={showAssetImageUploadModal}
//               >
//                 Upload Asset Image
//               </button>
//             </div>

//             <div className="mb-6">
//               <label className="block text-sm font-medium text-gray-700 mb-2">
//                 Editor Content
//               </label>
//               <JoditEditor
//                 ref={editor}
//                 value={editorContent}
//                 onChange={(newContent) => contentFieldChanged(newContent)}
//               />
//             </div>
//             <div className="mt-6">
//               <button
//                 type="submit"
//                 className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                 disabled={isSubmitting}
//               >
//                 {isSubmitting ? "Submitting..." : "Submit"}
//               </button>
//             </div>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default AddBlogForm;

import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "../../axios";
import AddBlog from "./AddBlog";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import JoditEditor from "jodit-react";
import AssetImageUpload from "./AssetImageUpload";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const MySwal = withReactContent(Swal);

// Validation schema with Yup
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  shortTitle: Yup.string().required("Short Title is required"),
  metaTitle: Yup.string().required("Meta Title is required"),
  metaDescription: Yup.string().required("Meta Description is required"),
  metaTag: Yup.string().required("Meta Tag is required"),
  slug: Yup.string().required("Slug is required"),
});

function AddBlogForm() {
  const editor = useRef(null);
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [blogImage, setBlogImage] = useState(null);
  const [closeForm, setCloseForm] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleBannerImageChange = (e) => {
    setBannerImage(e.target.files[0]); // New handler for blog image
  };

  const handleBlogImageChange = (e) => {
    setBlogImage(e.target.files[0]); // New handler for blog image
  };
  const contentFieldChanged = (data) => {
    setEditorContent(data);
  };

  const handleAssetImageUpload = (imageUrl) => {
    console.log("Received image URL:", imageUrl);
  };

  const showAssetImageUploadModal = () => {
    MySwal.fire({
      title: "Upload Asset Image",
      html: <AssetImageUpload onUpload={handleAssetImageUpload} />,
      showConfirmButton: false,
      showCloseButton: true,
      allowOutsideClick: false,
    });
  };

  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  const handleInputChange = (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value.trimStart());
  };

  const handleSubmit = async (values) => {
    setIsSubmitting(true);

    const { title, shortTitle, metaTitle, metaDescription, metaTag, slug } =
      values;

    if (!editorContent) {
      toast.error("Editor content is required");
      setIsSubmitting(false);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("title", title);
    formDataToSend.append("shortTitle", shortTitle);
    formDataToSend.append("metaTitle", metaTitle);
    formDataToSend.append("metaDescription", metaDescription);
    formDataToSend.append("metaTags", metaTag);
    formDataToSend.append("slug", slug.toLocaleLowerCase());
    formDataToSend.append("editorContent", editorContent);

    if (blogImage) {
      formDataToSend.append("image", blogImage);
    }
    if (coverImage) {
      formDataToSend.append("coverImage", coverImage);
    }
    if (bannerImage) {
      formDataToSend.append("bannerImage", bannerImage);
    }

    try {
      const response = await axios.post("/blog", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data.success) {
        toast.success("Blog has been added successfully");
        setCloseForm(true);
        navigate("/blog-management");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to add blog. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (closeForm) {
    return <AddBlog />;
  }

  return (
    <>
      <ToastContainer />
      <div className="max-w-4xl mx-auto p-4 bg-white shadow-md rounded-lg">
        <h2 className="text-2xl font-semibold mb-6">Add Blog Post</h2>
        <div className="flex justify-end">
          <button
            onClick={closeFormAndOpenPage}
            className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
            style={{
              backgroundImage:
                "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            <span className="ml-2">Back</span>
          </button>
        </div>

        <Formik
          initialValues={{
            title: "",
            shortTitle: "",
            metaTitle: "",
            metaDescription: "",
            metaTag: "",
            slug: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue }) => (
            <Form encType="multipart/form-data">
              <div className="mb-4">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <Field
                  type="text"
                  id="title"
                  name="title"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="shortTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Short Title
                </label>
                <Field
                  type="text"
                  id="shortTitle"
                  name="shortTitle"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="shortTitle"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="metaTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Title
                </label>
                <Field
                  type="text"
                  id="metaTitle"
                  name="metaTitle"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="metaTitle"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="metaDescription"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Description
                </label>
                <Field
                  as="textarea"
                  id="metaDescription"
                  name="metaDescription"
                  rows="3"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="metaDescription"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="metaTag"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Tag
                </label>
                <Field
                  type="text"
                  id="metaTag"
                  name="metaTag"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="metaTag"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="slug"
                  className="block text-sm font-medium text-gray-700"
                >
                  Slug
                </label>
                <Field
                  type="text"
                  id="slug"
                  name="slug"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                  onChange={(e) => handleInputChange(e, setFieldValue)}
                />
                <ErrorMessage
                  name="slug"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              
              <div className="mb-4">
                <label
                  htmlFor="blogImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Blog Image
                </label>
                <input
                  type="file"
                  id="blogImage"
                  name="blogImage"
                  accept="image/*"
                  onChange={handleBlogImageChange} // Handler for blog image
                  className="mt-1 block w-full"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="bannerImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Banner Image
                </label>
                <input
                  type="file"
                  id="bannerImage"
                  name="bannerImage"
                  accept="image/*"
                  onChange={handleBannerImageChange} // Handler for blog image
                  className="mt-1 block w-full"
                />
              </div>
              
              <div className="mb-4">
                <label
                  htmlFor="coverImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cover Image
                </label>
                <input
                  type="file"
                  id="coverImage"
                  name="coverImage"
                  accept="image/*"
                  onChange={handleCoverImageChange}
                  className="mt-1 block w-full"
                />
              </div>
              <div className="mb-4">
                <button
                  type="button"
                  className="bg-indigo-500 text-white px-4 py-2 rounded"
                  onClick={showAssetImageUploadModal}
                >
                  Upload Asset Image
                </button>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="editor"
                  className="block text-sm font-medium text-gray-700"
                >
                  Content
                </label>
                <JoditEditor
                  ref={editor}
                  value={editorContent}
                  onBlur={(newContent) => contentFieldChanged(newContent)}
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className={`w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddBlogForm;
