import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import AddBlog from "./AddBlog";
import JoditEditor from "jodit-react";

function BlogView(props) {
  const { blogId } = props.data;
  const API = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  const [blogImageUrl, setBlogImageUrl] = useState(null);
  const [closeForm, setCloseForm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    shortTitle: "",
    metaTitle: "",
    metaDescription: "",
    slug: "",
    image: "",
    editorContent: "",
  });
  const editor = useRef(null); // Editor.js reference

  useEffect(() => {
    if (blogId && API) {
      axios
        .get(`${API}/blog/${blogId}`)
        .then((response) => {
          if (!response.data.err) {
            const result = response.data.result;
            setBlogData(result);
            setFormData({
              title: result.title,
              shortTitle: result.shortTitle,
              metaTitle: result.metaTitle,
              metaDescription: result.metaDescription,
              slug: result.slug,
              image: result.image.url,
              editorContent: result.editorContent,
            });
            setBlogImageUrl(result.image.url);
            setBannerImageUrl(result.bannerImage.url);
            setCoverImageUrl(result.coverImage.url);
          } else {
            toast.error("Error loading blog content.");
          }
        })
        .catch((err) => {
          console.error("Error fetching blog content details:", err);
          toast.error("An error occurred while fetching the blog content.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [blogId, API]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  if (closeForm) {
    return <AddBlog />;
  }
  const contentFieldChanged = (content) => {
    setFormData((prevData) => ({ ...prevData, editorContent: content }));
  };

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (!blogData) {
    return <div className="text-center py-10">No blog content available.</div>;
  }

  return (
    <>
      <ToastContainer />
      <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6">View Blog</h2>
        <div className="flex justify-end">
          <button
            onClick={closeFormAndOpenPage}
            className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
            style={{
              backgroundImage:
                "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-arrow-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
              />
            </svg>
            <span className="ml-2">Back</span>
          </button>
        </div>

        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="shortTitle"
            className="block text-sm font-medium text-gray-700"
          >
            Short Title
          </label>
          <input
            type="text"
            id="shortTitle"
            name="shortTitle"
            value={formData.shortTitle}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="metaTitle"
            className="block text-sm font-medium text-gray-700"
          >
            Meta Title
          </label>
          <input
            type="text"
            id="metaTitle"
            name="metaTitle"
            value={formData.metaTitle}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="metaDescription"
            className="block text-sm font-medium text-gray-700"
          >
            Meta Description
          </label>
          <textarea
            id="metaDescription"
            name="metaDescription"
            value={formData.metaDescription}
            onChange={handleChange}
            rows="3"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>
        <div>
          <label
            htmlFor="slug"
            className="block text-sm font-medium text-gray-700"
          >
            Slug
          </label>
          <textarea
            id="slug"
            name="slug"
            value={formData.slug}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div>
          <label
            htmlFor="image"
            className="block text-sm font-medium text-gray-700"
          >
           Blog Image
          </label>
          <div className="mt-2">
            <>
              <img
                src={blogImageUrl}
                alt="Blog Image Preview"
                className="w-full h-auto border border-gray-300 rounded-md"
              />
            </>
          </div>
        </div>

        <div>
          <label
            htmlFor="image"
            className="block text-sm font-medium text-gray-700"
          >
            Banner Image
          </label>
          <div className="mt-2">
            <>
              <img
                src={bannerImageUrl}
                alt="Banner Image Preview"
                className="w-full h-auto border border-gray-300 rounded-md"
              />
            </>
          </div>
        </div>

        <div>
          <label
            htmlFor="image"
            className="block text-sm font-medium text-gray-700"
          >
            Cover Image
          </label>
          <div className="mt-2">
            <>
              <img
                src={coverImageUrl}
                alt="Cover Image Preview"
                className="w-full h-auto border border-gray-300 rounded-md"
              />
            </>
          </div>
        </div>

        <div>
          <label
            htmlFor="editorContent"
            className="block text-sm font-medium text-gray-700"
          >
            Editor Content
          </label>
          <JoditEditor
            ref={editor}
            value={formData.editorContent}
            onChange={contentFieldChanged}
            className="mt-1"
          />
        </div>
    
      </div>
    </>
  );
}

export default BlogView;
